.footer {
    max-width: 80rem;
    margin: 2rem auto;
}

.footer img {
    max-width: 30px;
    height: auto;
}

.footer a {
    color: black;
    text-decoration: none;
}
