.summary {
    text-align: center;
    max-width: 45rem;
    width: 90%;
    margin: auto;
    margin-top: -10rem;
    position: relative;
    background-color: #5c8314dc;
    color: whitesmoke;
    border-radius: 14px;
    padding: 1rem;
    box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25);
    animation: card-appear 2s ease-out forwards;
  }
  
  .summary h2 {
    font-size: 2rem;
    margin-top: 0;
  }
  
  @keyframes card-appear {
    from {
      opacity: 0;
      transform: translateY(10rem);
    }
  
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }