.summary {
  text-align: center;
  max-width: 45rem;
  width: 90%;
  margin: auto;
  margin-top: -10rem;
  position: relative;
  background-color: #5c8314dc;
  color: whitesmoke;
  border-radius: 14px;
  padding: 1rem;
  box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25);
  animation: card-appear 2s ease-out forwards;
}

.summary h2 {
  font-size: 2rem;
  margin-top: 0;
}

.map {
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  border: 1px solid #3f3f3f;
  max-width: 100%;
}

.map-container {
  max-width: 90rem;
  width: 100%;
  margin: 2rem auto;
  animation: card-appear 0.5s ease-in forwards;
}

.map-container h2 {
  text-align: center;
  color: whitesmoke;
}

.map-container p {
  text-align: center;
  color: whitesmoke;
}

@keyframes card-appear {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
