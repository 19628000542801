.video {
  padding: 56.25% 0 0 0;
  position: relative;
}

.iframe {
  position: absolute;
  margin: 2rem auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
}
