.midpage {
  width: 100%;
  margin: 2rem auto;
  animation: card-appear 2s ease-out forwards;
  position: relative;
  text-align: center;
}

.midpage h2 {
  color: black;
}

.cardContainer {
  max-width: 90rem;
  margin: auto;
}

.divisor {
  width: 30%;
  height: 4px;
  background-color: #5c8314dc;
  margin: 1rem auto;
}

@media (max-width: 756px) {
  .divisor {
    width: 70%;
  }
}

@keyframes card-appear {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
