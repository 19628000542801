html {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  margin: 0;
  background: linear-gradient(to bottom, #3f3f3f, whitesmoke) !important;
  padding-bottom: 50px;
  color: #3f3f3f !important;
}
