.card {
  padding: 1rem;
  margin: 1rem auto;
  margin-right: 1rem;
  margin-left: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
}

.card img {
  border-radius: 14px;
}
