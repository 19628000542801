.bottom-navbar {
  position: fixed;
  border-radius: 15px;
  bottom: 0;
  width: 90%;
  background-color: rgba(245, 245, 245, 0.822);
  display: flex;
  justify-content: space-around;
  padding: 10px;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 1;
}

.nav-link {
  text-decoration: none;
  color: #3f3f3f;
  font-weight: bold;
  padding: 5px 10px;
}

.nav-link:hover {
  color: #5c8314;
  cursor: pointer;
}

.nav-link:active {
  color: rgb(245, 241, 24);
}
