.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  z-index: 10;
}

.header img {
  margin-top: 10px;
  max-width: 100%;
  height: auto;
}

.main-image {
  width: 100%;
  margin-top: -40px;
  height: 25rem;
  z-index: 0;
  overflow: hidden;
}

.main-image img {
  width: 110%;
  height: 100%;
  object-fit: cover;
  transform: rotateZ(-4deg) translateY(-2rem) translateX(-1rem);
}

@media (max-width: 768px) {
  .header h1 {
    font-size: 24px;
  }
}
