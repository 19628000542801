.card {
  background-color: #c4e4cb !important;
  border: 1px solid #3f3f3f !important;
  box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25) !important;
}

.card h2 {
  color: #3f3f3f;
}

.card p {
  margin: 0.2rem;
}

.navigate {
  bottom: 0;
}
